import { useState } from "react";
import {
  Image,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
  Dimensions,
  ScrollView,
} from "react-native";
import { useDispatch } from "react-redux";
import { AntDesign } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { useAttendance } from "../../context/attendContext.js";
import { setStudent } from "../../store.js";

const deviceWidth = Dimensions.get("window").width;
let isWebResponsive = false;
if (deviceWidth < 768) {
  isWebResponsive = true;
}

const baseFontSize = 16;

function rem(value) {
  return value * baseFontSize;
}

export function RegisterScreen({ route, navigation }) {
  const [first, setFirst] = useState("");
  const [middle, setMiddle] = useState("");
  const [last, setLast] = useState("");
  const [invitor, setInvitor] = useState("");
  const [maintainer, setMaintainer] = useState("");
  const [number, setNumber] = useState("");
  const [textInputs, setTextInputs] = useState([]);
  const [numbers, setNumbers] = useState({});
  const [classNumber, setClassNumber] = useState("");
  const [webPhoto, setWebPhoto] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const dispatch = useDispatch();
  const { setIsLoading, registerStudent } = useAttendance();

  const onChangeFirst = (e) => {
    setFirst(e);
  };
  const onChangeMiddle = (e) => {
    setMiddle(e);
  };
  const onChangeLast = (e) => {
    setLast(e);
  };

  const onChangeInvitor = (e) => {
    setInvitor(e);
  };
  const onChangeMaintainer = (e) => {
    setMaintainer(e);
  };

  const onChangeNumber = (e) => {
    setNumber(e);
  };

  const onChangeClassNumber = (e) => {
    setClassNumber(e);
  };

  const onAddTextInput = () => {
    const key = textInputs.length;
    const newTextInput = (
      <View key={key} style={{ marginTop: 18 }}>
        <TextInput
          style={styles.numberInput}
          onChangeText={(num) => handleNumbers(key, num)}
          value={numbers[key]}
          placeholder="your phone number"
          keyboardType="phone-pad"
        />
      </View>
    );

    setTextInputs([...textInputs, newTextInput]);
  };

  const handleNumbers = (key, num) => {
    setNumbers({ ...numbers, [key]: num });
  };

  const handleImagePress = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.onchange = (e) => {
      setWebPhoto(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    };

    input.click();
  };

  const onBtnPress = async () => {
    if (!first.trim()) {
      return window.alert("First name is empty!");
    }
    if (!last.trim()) {
      return window.alert("Last name is empty!");
    }
    if (!classNumber.trim()) {
      return window.alert("Class name is empty!");
    }

    if (!webPhoto.trim()) {
      return window.alert("Profile image is empty!");
    }
    setIsLoading(true);
    await registerStudent({
      name: `${first} ${middle} ${last}`,
      invitor1: invitor,
      invitor2: maintainer,
      classNumber,
      phoneNumber:
        Object.keys(numbers).length > 0
          ? [number].concat(Object.values(numbers)).join(",")
          : number,
      uri: selectedFile,
    })
      .then((result) => {
        dispatch(setStudent(result));
        navigation.replace("Detail");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.title}>Register</Text>
        </View>
        <View style={styles.nameView}>
          <Text style={{ ...styles.text, color: "white" }}>Name</Text>
          <View style={styles.nameDetail}>
            <Pressable style={styles.photoContainer} onPress={handleImagePress}>
              <View style={styles.photoView}>
                {webPhoto ? (
                  <Image source={{ uri: webPhoto }} style={{ flex: 1 }} />
                ) : (
                  <Ionicons name="person" size={140} color="black" />
                )}
              </View>
            </Pressable>

            <View style={styles.nameViewContainer}>
              <View style={styles.nameContainer}>
                <View style={{ ...styles.nameText, borderBottomWidth: 1 }}>
                  <Text>First name</Text>
                </View>
                <View style={{ ...styles.nameText, borderBottomWidth: 1 }}>
                  <Text>Middle name</Text>
                </View>
                <View style={styles.nameText}>
                  <Text>Last name</Text>
                </View>
              </View>
              <View style={styles.inputContainer}>
                <View
                  style={{
                    ...styles.nameInputContainer,
                    borderBottomColor: "lightgrey",
                    borderBottomWidth: 1,
                  }}
                >
                  <TextInput
                    style={styles.nameInput}
                    onChangeText={onChangeFirst}
                    value={first}
                    placeholder=""
                    keyboardType="default"
                  />
                </View>
                <View
                  style={{
                    ...styles.nameInputContainer,
                    borderBottomColor: "lightgrey",
                    borderBottomWidth: 1,
                  }}
                >
                  <TextInput
                    style={styles.nameInput}
                    onChangeText={onChangeMiddle}
                    value={middle}
                    placeholder=""
                    keyboardType="default"
                  />
                </View>
                <View style={styles.nameInputContainer}>
                  <TextInput
                    style={styles.nameInput}
                    onChangeText={onChangeLast}
                    value={last}
                    placeholder=""
                    keyboardType="default"
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.invitorView}>
          <Text style={{ ...styles.text, color: "white" }}>Invitor</Text>
          <View style={styles.invitorDetail}>
            <View style={{ flex: 1.3, flexDirection: "row" }}>
              <View
                style={{ ...styles.nameInputContainer, borderBottomWidth: 1 }}
              >
                <TextInput
                  style={styles.nameInput}
                  onChangeText={onChangeInvitor}
                  value={invitor}
                  placeholder=""
                  keyboardType="default"
                />
              </View>
              <View
                style={{
                  ...styles.nameInputContainer,
                  borderBottomWidth: 1,
                  borderLeftWidth: 1,
                }}
              >
                <TextInput
                  style={styles.nameInput}
                  onChangeText={onChangeMaintainer}
                  value={maintainer}
                  placeholder=""
                  keyboardType="default"
                />
              </View>
            </View>
            <View style={{ flex: 1.3, flexDirection: "row" }}>
              <View
                style={{
                  flex: 1,
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "white",
                    marginHorizontal: 4,
                    paddingVertical: 6,
                    backgroundColor: "#4137b7",
                    borderRadius: 5,
                  }}
                >
                  Class number
                </Text>
              </View>
              <View
                style={{
                  ...styles.nameInputContainer,
                  flex: 1,
                }}
              >
                <TextInput
                  style={{ ...styles.nameInput }}
                  onChangeText={onChangeClassNumber}
                  value={classNumber}
                  placeholder=""
                  keyboardType="default"
                />
              </View>
            </View>
          </View>
        </View>
        <View style={styles.numberView}>
          <View
            style={{
              ...styles.text,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: 20,
                fontWeight: "700",
                color: "white",
              }}
            >
              Phone number
            </Text>
            <Pressable style={styles.addBtn} onPress={onAddTextInput}>
              <Text style={{ color: "white", fontSize: 16 }}>Add</Text>
              <AntDesign name="pluscircleo" size={16} color="white" />
            </Pressable>
          </View>

          <ScrollView style={styles.numberDetail}>
            <TextInput
              style={styles.numberInput}
              onChangeText={onChangeNumber}
              value={number}
              placeholder="your phone number"
              keyboardType="phone-pad"
            />
            {textInputs.map((textInput) => textInput)}
          </ScrollView>
          <View
            style={{
              flexDirection: "row",
              gap: 32,
            }}
          >
            <Pressable
              style={{
                marginVertical: 16,
                backgroundColor: "#6950f4",
                borderRadius: 5,
              }}
              onPress={onBtnPress}
            >
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: "600",
                  color: "white",
                  padding: 8,
                }}
              >
                CONFIRM
              </Text>
            </Pressable>
            <Pressable
              style={{
                marginVertical: 16,
                backgroundColor: "grey",
                borderRadius: 5,
              }}
              onPress={() => navigation.goBack()}
            >
              <Text
                style={{
                  fontSize: 15,
                  fontWeight: "600",
                  color: "white",
                  padding: 8,
                }}
              >
                CANCEL
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    height: "100%",
    flex: 1,
  },
  header: {
    flex: 0.5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 36,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  nameView: {
    flex: 1.5,
    display: "flex",
    alignItems: "center",
  },
  nameDetail: {
    flex: 5,
    display: "flex",
    flexDirection: "row",
    width: "50%",
  },
  photoContainer: {
    flex: 1,

    display: "flex",
    justifyContent: "center",
  },
  photoView: {
    flex: 1,
  },
  nameViewContainer: {
    flex: 2,
    display: "flex",
    flexDirection: "row",
    height: "100%",
    backgroundColor: "#b4b0f4",
  },
  nameContainer: {
    display: "flex",
  },
  nameText: {
    flex: 0.5,
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderStyle: "solid",
    borderRightWidth: 1,
    borderColor: "lightgrey",
    display: "flex",
    justifyContent: "center",
  },
  inputContainer: {
    display: "flex",
    flex: 2,
  },
  nameInputContainer: {
    flex: 0.5,
    justifyContent: "center",
    borderColor: "lightgrey",
  },
  nameInput: {
    paddingVertical: 6,
    paddingHorizontal: 8,
    borderRadius: 5,
    marginHorizontal: 4,
    marginVertical: 4,

    backgroundColor: "white",

    fontSize: isWebResponsive ? 16 : 14,
  },

  text: {
    width: "50%",
    fontSize: 20,
    fontWeight: "700",
    paddingVertical: 8,
    backgroundColor: "#4137b7",
    textAlign: "center",
  },
  invitorView: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  invitorDetail: {
    flex: 1.5,
    width: "50%",
    display: "flex",
    backgroundColor: "#b4b0f4",
  },

  numberView: {
    flex: 3,

    display: "flex",
    alignItems: "center",
  },
  addBtn: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    top: 8,
    right: 8,
    gap: 4,
    padding: 4,
    backgroundColor: "gray",
    borderRadius: 5,
  },
  numberDetail: {
    flex: 3,
    width: "50%",
    backgroundColor: "#b4b0f4",
    borderBottomEndRadius: 10,
    borderBottomStartRadius: 10,
  },
  numberInput: {
    marginHorizontal: 8,
    marginVertical: 4,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
    backgroundColor: "white",
    fontSize: 24,
  },
});
