import React from "react";
import { View, Modal, ActivityIndicator, StyleSheet } from "react-native";

const Loading = ({ isLoading }) => {
  return (
    <Modal transparent visible={isLoading} onRequestClose={() => {}}>
      <View style={styles.container}>
        <View style={styles.loadingWrapper}>
          <ActivityIndicator size="large" color="#00ff00" />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)", // 어두운 배경
  },
  loadingWrapper: {
    backgroundColor: "#fff", // 밝은 로딩 컴포넌트 배경
    padding: 20,
    borderRadius: 10,
  },
});

export default Loading;
