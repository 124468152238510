import { View, Text, StyleSheet, Button, Pressable, Image } from "react-native";
import { Camera, CameraType } from "expo-camera";
import { useState } from "react";
import { FontAwesome } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Fontisto } from "@expo/vector-icons";
import * as FileSystem from "expo-file-system";
import * as ImagePicker from "expo-image-picker";

export function CameraScreen({ route, navigation }) {
  const [type, setType] = useState(CameraType.back);
  const [cameraRef, setCameraRef] = useState(null);
  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [photo, setPhoto] = useState(null);
  const stackState = navigation.getState();
  const prevScreenInfo = stackState.routes[stackState.index - 1];

  if (!permission) {
    return <View />;
  }

  if (!permission.granted) {
    return (
      <View style={styles.container}>
        <Text style={{ textAlign: "center" }}>
          We need your permission to show the camera
        </Text>
        <Button onPress={requestPermission} title="grant permission" />
      </View>
    );
  }

  const takePicture = async () => {
    if (cameraRef) {
      const { uri } = await cameraRef.takePictureAsync();
      setPhoto(uri);
    }
  };

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    });

    if (!result.canceled) {
      setPhoto(result.assets[0].uri);
    }
  };

  const onConfirmPress = () => {
    navigation.navigate(prevScreenInfo.name, { photo });
  };

  const onCancelPress = async () => {
    await deleteUriData(photo);
    setPhoto(null);
  };

  const deleteUriData = async (uri) => {
    try {
      await FileSystem.deleteAsync(uri);
      console.log("Successfully deleted:", uri);
    } catch (error) {
      console.error("Failed to delete:", uri, error);
    }
  };

  const toggleCameraType = () => {
    setType((current) =>
      current === CameraType.back ? CameraType.front : CameraType.back
    );
  };

  return (
    <View style={styles.container}>
      {photo ? (
        <View style={styles.imageContainer}>
          <Image source={{ uri: photo }} style={{ flex: 1 }} />
          <Pressable
            style={{
              ...styles.pressable,
              left: "30%",
              backgroundColor: "#6950f4",
            }}
            onPress={onConfirmPress}
          >
            <Text style={{ color: "white", fontWeight: "600" }}>Confirm</Text>
          </Pressable>
          <Pressable
            style={{
              ...styles.pressable,
              left: "60%",
              backgroundColor: "white",
            }}
            onPress={onCancelPress}
          >
            <Text style={{ color: "gray", fontWeight: "600" }}>Cancel</Text>
          </Pressable>
        </View>
      ) : (
        <Camera
          style={styles.camera}
          type={type}
          ref={(ref) => setCameraRef(ref)}
        >
          <View style={styles.buttonContainer}>
            <Pressable style={styles.button} onPress={takePicture}>
              <FontAwesome name="camera" size={36} color="gray" />
            </Pressable>
            <Pressable style={styles.button} onPress={toggleCameraType}>
              <MaterialCommunityIcons
                name="rotate-3d-variant"
                size={36}
                color="gray"
              />
            </Pressable>
            <Pressable style={styles.button} onPress={pickImage}>
              <Fontisto name="photograph" size={36} color="black" />
            </Pressable>
          </View>
        </Camera>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "transparent",
    justifyContent: "space-around",
    margin: 64,
  },
  button: {
    alignSelf: "flex-end",
    alignItems: "center",
    backgroundColor: "white",
    padding: 12,
    borderRadius: 50,
  },
  text: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
  },
  imageContainer: {
    flex: 1,
    position: "relative",
  },
  pressable: {
    position: "absolute",
    bottom: "10%",
    padding: 8,
    borderRadius: 8,
  },
});
