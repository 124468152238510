import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  Platform,
  Dimensions,
  Modal,
  Alert,
  FlatList,
} from "react-native";
import { useSelector } from "react-redux";
import Checkbox from "expo-checkbox";
import { FontAwesome5 } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useAttendance } from "../context/attendContext";
import { useState } from "react";

const deviceWidth = Dimensions.get("window").width;
let isTablet = false;
let isWebResponsive = false;
if (deviceWidth > 768) {
  isTablet = true;
}
if (deviceWidth < 768) {
  isWebResponsive = true;
}

export function DetailScreen({ route, navigation }) {
  const {
    id,
    name,
    phoneNumber,
    invitor1,
    invitor2,
    classNumber,
    GSN,
    JDSN,
    url,
    isDropped,
    isInterview,
    isCard,
    missedClass,
    attendRate,
    latestAttend,
  } = useSelector((state) => state.student);

  const [modalVisible, setModalVisible] = useState(false);
  const [checks, setChecks] = useState({
    isDropped: isDropped ? true : false,
    isInterview: isInterview ? true : false,
    isCard: isCard ? true : false,
  });
  const [checkVisible, setCheckVisible] = useState(false);
  const [catchup, setCatchup] = useState(missedClass);
  const {
    data,
    setData,
    setIsLoading,
    attendClass,
    catchUpClass,
    checkStudent,
    isWeb,
  } = useAttendance();

  const handleAttendAlert = async () => {
    setIsLoading(true);
    await attendClass(id, true, classNumber)
      .then((result) => {
        isWeb ? window.alert(result.message) : Alert.alert(result.message);
        navigation.navigate("HomeCheck");
      })
      .catch((err) => {
        const errorMessage = `Error occured!\n:(${err})`;
        isWeb ? window.alert(errorMessage) : Alert.alert(errorMessage);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCatchupAlert = async (classSessionId, classDate) => {
    setIsLoading(true);
    await catchUpClass(id, true, classNumber, classDate)
      .then((result) => {
        isWeb ? window.alert(result.message) : Alert.alert(result.message);
        const newMissedClass = [
          ...catchup.filter((item) => item.classSessionId !== classSessionId),
        ];
        setCatchup(newMissedClass);
        setData({
          ...data,
          [id]: { ...data[id], missedClass: newMissedClass },
        });
      })
      .catch((err) => {
        const errorMessage = `Error occured!\n:(${err})`;
        isWeb ? window.alert(errorMessage) : Alert.alert(errorMessage);
      })
      .finally(() => setIsLoading(false));
  };

  const handleAttend = async () => {
    if (isWeb) {
      if (window.confirm("Are you sure?")) {
        handleAttendAlert();
      }
    } else {
      Alert.alert("Confirm", "Are you sure?", [
        {
          text: "Yes",
          onPress: handleAttendAlert,
        },
        { text: "No", onPress: () => console.log("No") },
      ]);
    }
  };

  const handleCatchUp = (classSessionId, classDate) => {
    if (isWeb) {
      if (window.confirm("Did you really catch up the class?")) {
        handleCatchupAlert(classSessionId, classDate);
      }
    } else {
      Alert.alert(
        "Catch-up class",
        "Did you really catch up the class?",
        [
          {
            text: "Yes",
            onPress: () => handleCatchupAlert(classSessionId, classDate),
          },
          {
            text: "No",
            onPress: () => console.log("No Pressed"),
            style: "cancel",
          },
        ],
        { cancelable: false }
      );
    }
  };

  const handleCheckConfirm = async () => {
    setIsLoading(true);
    await checkStudent(id, checks)
      .then((result) => {
        isWeb ? window.alert(result.message) : Alert.alert(result.message);
        setData({
          ...data,
          [id]: {
            ...data[id],
            isCard: checks.isCard,
            isInterview: checks.isInterview,
            isDropped: checks.isDropped,
          },
        });
      })
      .catch((err) => {
        const errorMessage = `Error occured!\n:(${err})`;
        isWeb ? window.alert(errorMessage) : Alert.alert(errorMessage);
      })
      .finally(() => {
        setCheckVisible(false);
        setIsLoading(false);
      });
  };

  const handleCheckAlert = () => {
    if (isWeb) {
      if (window.confirm("Are you sure?")) {
        handleCheckConfirm();
      }
    } else {
      Alert.alert("Notification", "Are you sure?", [
        { text: "Yes", onPress: handleCheckConfirm },
        { text: "No", onPress: () => console.log("no") },
      ]);
    }
  };

  const Item = ({ classSessionId, classTitle, classDate }) => (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        paddingVertical: isTablet ? 4 : 2,
        paddingHorizontal: isTablet ? 6 : 2,
      }}
    >
      <View
        style={{
          flex: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={styles.modalText}>{classTitle}</Text>
      </View>

      <Pressable
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={() => handleCatchUp(classSessionId, classDate)}
      >
        <MaterialCommunityIcons
          name="book-check-outline"
          size={24}
          color="black"
        />
      </Pressable>
    </View>
  );
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Student</Text>
        <Pressable
          style={styles.editBtn}
          onPress={() => navigation.navigate("Edit")}
        >
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesome5 name="edit" size={24} color="black" />
            <Text>Edit</Text>
          </View>
        </Pressable>
      </View>
      <View style={styles.studentContainer}>
        <View style={styles.infoContainer}>
          <View style={styles.h2Container}>
            <Text style={styles.h2}>Information</Text>
          </View>
          <View style={styles.basicContainer}>
            <View style={styles.imgContainer}>
              <Image
                source={{ uri: url }}
                style={{ width: "100%", height: "100%" }}
                resizeMode="contain"
              />
            </View>
            <View style={styles.nameContainer}>
              <View style={{ ...styles.h3Container, flex: 1 }}>
                <Text
                  style={{
                    ...styles.h3,
                    flex: 1,
                    lineHeight: isTablet ? 50 : 40,
                  }}
                >
                  Name
                </Text>
              </View>
              <Pressable
                style={styles.h3Content}
                onPress={() => setCheckVisible(true)}
              >
                <Text
                  style={{ flex: 1, lineHeight: isTablet ? 50 : 40 }}
                  numberOfLines={1}
                >
                  {name}
                </Text>
              </Pressable>

              <View style={{ ...styles.h3Container, flex: 1 }}>
                <Text style={styles.h3}>Phone Number</Text>
              </View>
              <View style={{ ...styles.h3Content, flex: 2 }}>
                <Text>{phoneNumber}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.invitorContainer}>
          <View style={styles.h2Container}>
            <Text style={styles.h2}>Invitors</Text>
          </View>
          <View style={styles.invitorBox}>
            <View style={styles.invitorView}>
              <Text style={styles.textValue}>{invitor1}</Text>
            </View>
            <View style={styles.invitorView}>
              <Text style={styles.textValue}>{invitor2}</Text>
            </View>
          </View>
        </View>
        <View style={styles.classContainer}>
          <View style={{ ...styles.h2Container, flex: 1 }}>
            <Text style={styles.h2}>Class Information</Text>
          </View>
          <View style={{ flex: 5 }}>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={styles.h3Container}>
                <Text style={styles.h3}>Class number</Text>
              </View>
              <View style={styles.h3Content}>
                <Text style={styles.textValue}>{classNumber}</Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={styles.h3Container}>
                <Text style={styles.h3}>GSN</Text>
              </View>
              <View style={styles.h3Content}>
                <Text style={styles.textValue}>{GSN}</Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", flex: 1 }}>
              <View style={styles.h3Container}>
                <Text style={styles.h3}>JDSN</Text>
              </View>
              <View style={styles.h3Content}>
                <Text style={styles.textValue}>{JDSN}</Text>
              </View>
            </View>
          </View>
          <View style={styles.attendanceInfo}>
            <View style={styles.h2Container}>
              <Text style={styles.h2}>Attendance</Text>
            </View>
            <View style={{ flexDirection: "row", flex: 2.5 }}>
              <View style={{ ...styles.h3Container, flex: 1 }}>
                <Text style={styles.h3}>Missed Class</Text>
              </View>
              <Pressable
                style={styles.h3Content}
                onPress={() => setModalVisible(true)}
              >
                <Text>Tab to see</Text>
              </Pressable>
              <View style={{ ...styles.h3Container, flex: 1 }}>
                <Text style={styles.h3}>Rate</Text>
              </View>
              <View style={styles.h3Content}>
                <Text>{attendRate}%</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Pressable style={styles.button} onPress={handleAttend}>
          <Text style={styles.btnText}>Attend</Text>
        </Pressable>
        <Pressable
          style={{ ...styles.button, backgroundColor: "grey" }}
          onPress={() => navigation.goBack()}
        >
          <Text style={styles.btnText}>Back</Text>
        </Pressable>
      </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          if (isWeb) {
            window.alert("Modal has been closed.");
          } else {
            Alert.alert("Modal has been closed.");
          }

          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View style={styles.modalTitle}>
              <Text
                style={{
                  ...styles.modalTitleText,
                  flex: 4,
                  borderRightColor: "white",
                  borderRightWidth: 1,
                }}
              >
                Missed class
              </Text>
              <Text style={{ ...styles.modalTitleText, flex: 1 }}>
                catch-up
              </Text>
            </View>
            <View style={{ width: "100%", flex: 1 }}>
              <FlatList
                style={{
                  paddingVertical: isTablet ? 8 : 4,
                  borderColor: "#13baf7",
                  borderRightWidth: 1,
                  borderLeftWidth: 1,
                }}
                data={catchup}
                renderItem={({ item }) => (
                  <Item
                    classSessionId={item.classSessionId}
                    classTitle={item.classTitle}
                    classDate={item.classDate}
                  />
                )}
                keyExtractor={(item) => item.classSessionId}
              />
            </View>

            <View
              style={{
                width: "100%",
                flexDirection: "row",
                borderColor: "#13baf7",
                borderWidth: 1,
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  flex: 3,
                  alignItems: "center",
                  backgroundColor: "#13baf7",
                  paddingVertical: isTablet ? 8 : 4,
                  paddingHorizontal: isTablet ? 6 : 2,
                }}
              >
                <Text
                  style={{
                    fontSize: isTablet ? 16 : 12,
                    fontWeight: isTablet ? 800 : 400,
                    color: "white",
                  }}
                >
                  Latest Attend
                </Text>
              </View>
              <View
                style={{
                  flex: 2,
                  alignItems: "center",
                  paddingVertical: isTablet ? 8 : 4,
                  paddingHorizontal: isTablet ? 6 : 2,
                }}
              >
                <Text
                  style={{
                    fontSize: isTablet ? 16 : 12,
                    fontWeight: isTablet ? 800 : 400,
                  }}
                >
                  {latestAttend}
                </Text>
              </View>
            </View>

            <View>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => setModalVisible(false)}
              >
                <Text style={{ ...styles.textStyle, color: "#2196F3" }}>
                  Return
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="slide"
        transparent={true}
        visible={checkVisible}
        onRequestClose={() => {
          if (isWeb) {
            window.alert("Modal has been closed.");
          } else {
            Alert.alert("Modal has been closed.");
          }
          setCheckVisible(!checkVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View
            style={{
              ...styles.modalView,
              flex: 0.4,
              width: isWeb && !isWebResponsive ? "30%" : "70%",
            }}
          >
            <View style={{ ...styles.modalTitle, justifyContent: "center" }}>
              <Text
                style={{
                  ...styles.modalTitleText,
                  fontWeight: isWeb ? 800 : 600,
                  fontSize: isWeb ? 16 : 14,
                }}
              >
                Check-out CT
              </Text>
            </View>
            <View
              style={{
                width: "100%",
                borderColor: "#13baf7",
                borderRightWidth: 1,
                borderLeftWidth: 1,
              }}
            >
              <View style={styles.section}>
                <Checkbox
                  style={styles.checkbox}
                  value={checks.isCard}
                  onValueChange={() =>
                    setChecks({ ...checks, isCard: !checks.isCard })
                  }
                  color={checks.isCard ? "#13baf7" : undefined}
                />
                <Text style={styles.paragraph}>Center card</Text>
              </View>
              <View style={styles.section}>
                <Checkbox
                  style={styles.checkbox}
                  value={checks.isInterview}
                  onValueChange={() =>
                    setChecks({ ...checks, isInterview: !checks.isInterview })
                  }
                  color={checks.isInterview ? "#13baf7" : undefined}
                />
                <Text style={styles.paragraph}>Interview</Text>
              </View>
            </View>
            <View style={{ ...styles.modalTitle, justifyContent: "center" }}>
              <Text
                style={{
                  ...styles.modalTitleText,
                  fontWeight: isWeb ? 800 : 600,
                  fontSize: isWeb ? 16 : 14,
                }}
              >
                Drop the student
              </Text>
            </View>
            <View
              style={{
                ...styles.section,
                width: "100%",
                borderColor: "#13baf7",
                borderRightWidth: 1,
                borderLeftWidth: 1,
                borderBottomWidth: 1,
              }}
            >
              <Checkbox
                style={styles.checkbox}
                value={checks.isDropped}
                onValueChange={() =>
                  setChecks({ ...checks, isDropped: !checks.isDropped })
                }
                color={checks.isDropped ? "#13baf7" : undefined}
              />
              <Text style={styles.paragraph}>Drop</Text>
            </View>
            <View
              style={{
                width: isWeb && !isWebResponsive ? "50%" : "70%",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Pressable
                style={[styles.button, styles.buttonConfirm]}
                onPress={handleCheckAlert}
              >
                <Text style={{ ...styles.textStyle }}>Confirm</Text>
              </Pressable>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => setCheckVisible(false)}
              >
                <Text style={{ ...styles.textStyle, color: "#2196F3" }}>
                  Return
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  header: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
  },
  title: {
    fontSize: 24,
    fontStyle: "italic",
    fontWeight: "bold",
  },
  editBtn: {
    position: "absolute",
    top: isTablet ? 32 : 16,
    right: isTablet ? 32 : 16,

    backgroundColor: "#ffffff",
    ...Platform.select({
      ios: {
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.5,
        shadowRadius: 10,
      },
      android: {
        elevation: 10,
      },
    }),
  },
  h2Container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    padding: isTablet ? 8 : 4,
    backgroundColor: "#13baf7",
  },
  h2: {
    fontSize: isTablet ? 20 : 16,
    fontWeight: "500",
    color: "white",
  },
  h3Container: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    backgroundColor: "#70d5f9",
    borderColor: "#13baf7",
    borderStyle: "solid",
    borderWidth: 1,
  },
  h3: {
    fontSize: isTablet ? 18 : 14,
    fontWeight: "500",
    textAlign: "center",
  },
  h3Content: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderColor: "#13baf7",
    borderStyle: "solid",
    borderWidth: 1,
  },
  studentContainer: {
    flex: 8,
    width: "80%",
    borderColor: "#13baf7",
    borderStyle: "solid",
    borderWidth: 1,
  },
  infoContainer: {
    flex: 1,
  },
  basicContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 8,
    width: "100%",
  },
  imgContainer: {
    width: "50%",
  },
  nameContainer: {
    width: "50%",
  },
  invitorContainer: {
    flex: 0.3,
  },
  invitorBox: { flexDirection: "row", flex: 2 },
  invitorView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#13baf7",
    borderStyle: "solid",
    borderWidth: 1,
  },
  classContainer: {
    flex: 1,
  },
  attendanceInfo: {
    flex: 3,
  },
  footer: {
    flex: 1,
    flexDirection: "row",
    width: "80%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  button: {
    marginVertical: 16,
    backgroundColor: "#6950f4",
    borderRadius: 5,
    padding: 4,
  },
  btnText: {
    fontSize: isTablet ? 15 : 11,
    fontWeight: "600",
    color: "white",
    padding: 8,
  },
  textValue: {
    fontSize: isTablet ? 18 : 14,
    fontWeight: isTablet ? 500 : 400,
  },
  buttonClose: {
    backgroundColor: "white",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    flex: isTablet ? 0.5 : 0.4,
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    paddingBottom: 0,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: isTablet ? "70%" : "90%",
  },
  modalTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#13baf7",
    paddingVertical: 8,
    width: "100%",
  },
  modalTitleText: {
    color: "white",
    fontSize: isTablet ? 16 : 12,
    fontWeight: isTablet ? 800 : 400,
    textAlign: "center",
  },
  modalText: {
    textAlign: "center",
    fontSize: isTablet ? 20 : 16,
    fontStyle: "italic",
  },
  buttonConfirm: {
    backgroundColor: "#2196F3",
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 4,
  },
  paragraph: {
    fontSize: 15,
  },
  checkbox: {
    margin: 8,
  },
});
