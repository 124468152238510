import React, { useEffect, useState } from "react";
import { Alert } from "react-native";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Modal,
  TextInput,
  Platform,
  Dimensions,
} from "react-native";

import { Agenda } from "react-native-calendars";
import { Card } from "react-native-paper";
import { useAttendance } from "../context/attendContext";
import { AntDesign } from "@expo/vector-icons";

const deviceWidth = Dimensions.get("window").width;
let isWebResponsive = false;
if (deviceWidth < 768) {
  isWebResponsive = true;
}

export function CalendarScreen({ navigation }) {
  const [items, setItems] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [inputValues, setInputValues] = useState({
    classNumber: "",
    classTitle: "",
  });
  const [date, setDate] = useState(undefined);
  const [selected, setSelected] = useState("");
  const [isUpdate, setIsUpdate] = useState({
    id: undefined,
    update: false,
  });
  const {
    getClasses,
    makeClass,
    renameClass,
    deleteClass,
    isWeb,
    setIsLoading,
  } = useAttendance();

  const loadItems = async (day) => {
    const data = await getClasses(day?.dateString || day);

    setItems(data);
  };

  const renderItem = (item) => {
    return <ListItem item={item} />;
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    await deleteClass(id)
      .then((result) => {
        isWeb ? window.alert(result.message) : Alert.alert(result.message);
        const newItems = {};
        Object.keys(items).forEach((key) => {
          newItems[key] = items[key].filter((i) => i.classSessionId !== id);
        });

        setItems(newItems);
      })
      .finally(() => setIsLoading(false));
  };

  const deleteClassAlert = async (id) => {
    if (isWeb) {
      if (window.confirm("Are you sure?")) {
        handleDelete(id);
      }
    } else {
      Alert.alert("Delete the class", "Are you sure to delete?", [
        {
          text: "Yes",
          onPress: () => handleDelete(id),
        },
        { text: "No", onPress: () => console.log("No") },
      ]);
    }
  };

  const handleItemClick = (classSessionId, classNumber, classTitle) => {
    setIsUpdate({ id: classSessionId, update: true });
    setModalVisible(true);
    setInputValues({ classNumber, classTitle });
  };

  const ListItem = React.memo(({ item }) => {
    return (
      <Pressable
        style={styles.item}
        onPress={() =>
          handleItemClick(
            item.classSessionId,
            item.classNumber,
            item.classTitle
          )
        }
      >
        <Card style={{ backgroundColor: "white" }}>
          <Card.Content
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                ...Platform.select({
                  ios: {
                    flex: 5,
                  },
                  android: {
                    flex: 8,
                  },
                  web: {
                    flex: 8,
                  },
                }),
                gap: 8,
              }}
            >
              <View
                style={{
                  ...styles.textBox,
                  backgroundColor: item.color ? item.color : "pink",
                  flex: 1,
                  ...Platform.select({
                    ios: {
                      shadowColor: "#000",
                      shadowOffset: { width: 0, height: 2 },
                      shadowOpacity: 0.3,
                      shadowRadius: 2,
                    },
                    android: {
                      elevation: 5,
                    },
                  }),
                }}
              >
                <Text style={{ ...styles.text, color: "white" }}>
                  {item.classNumber}
                </Text>
              </View>
              <View
                style={{
                  ...styles.textBox,
                  ...Platform.select({
                    ios: { flex: 3 },
                    android: { flex: 3 },
                    web: { flex: 5 },
                  }),
                }}
              >
                <Text style={styles.text}>{item.classTitle}</Text>
              </View>
            </View>
            <Pressable
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
              onPress={() => deleteClassAlert(item.classSessionId)}
            >
              <AntDesign name="closecircle" size={24} color="grey" />
            </Pressable>
          </Card.Content>
        </Card>
      </Pressable>
    );
  });

  const handleModal = (data) => {
    const _date = data.dateString;
    setDate(_date);
    setModalVisible(!modalVisible);
  };

  const handleChangeText = (text, key) =>
    setInputValues({ ...inputValues, [key]: text });

  const resetInitial = () => {
    setModalVisible(!modalVisible);
    setInputValues({ classNumber: "", classTitle: "" });
    setDate(undefined);
  };

  const handleConfirm = async () => {
    if (!inputValues.classNumber.trim()) {
      return isWeb
        ? window.alert("Class number is empty!")
        : Alert.alert("Class number is empty!");
    }

    if (!inputValues.classTitle.trim()) {
      return isWeb
        ? window.alert("Class title is empty!")
        : Alert.alert("Class title is empty!");
    }

    setIsLoading(true);
    if (!isUpdate.update) {
      await makeClass(
        inputValues.classTitle,
        inputValues.classNumber,
        date
      ).then((result) => {
        resetInitial();
        setIsLoading(false);
        console.log({
          ...items,
          [result.classDate]: [...items[result.classDate], result],
        });
        setItems((prevItems) => ({
          ...prevItems,
          [result.classDate]: [...prevItems[result.classDate], result],
        }));
      });
    } else {
      await renameClass(
        isUpdate.id,
        inputValues.classTitle,
        inputValues.classNumber
      ).then(() => {
        resetInitial();
        setIsUpdate({ id: undefined, update: false });
        setIsLoading(false);
      });
    }
  };

  const getWeeksAgo = () => {
    let date = new Date();
    date.setDate(date.getDate() - 15);

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    setSelected(getWeeksAgo());
  }, []);

  return (
    <View style={styles.container}>
      <Agenda
        items={items}
        selected={selected}
        refreshControl={null}
        showClosingKnob={true}
        refreshing={false}
        loadItemsForMonth={loadItems}
        renderItem={renderItem}
        onDayPress={handleModal}
      />
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          if (isWeb) {
            window.alert("Modal has been closed.");
          } else {
            Alert.alert("Modal has been closed.");
          }
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View
              style={{
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Text style={styles.modalText}>class number</Text>
              <TextInput
                style={styles.textInput}
                onChangeText={(text) => handleChangeText(text, "classNumber")}
                value={inputValues.classNumber}
                placeholder=""
                keyboardType="default"
              />
            </View>
            <View
              style={{
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Text style={styles.modalText}>class title</Text>
              <TextInput
                style={styles.textInput}
                onChangeText={(text) => handleChangeText(text, "classTitle")}
                value={inputValues.classTitle}
                placeholder=""
                keyboardType="default"
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                gap: 32,
                marginTop: 24,
              }}
            >
              <Pressable
                style={[styles.button, styles.buttonConfirm]}
                onPress={handleConfirm}
              >
                <Text style={styles.textStyle}>
                  {!isUpdate.update ? "Confirm" : "Rename"}
                </Text>
              </Pressable>
              <Pressable
                style={[styles.button, styles.buttonClose]}
                onPress={() => {
                  setModalVisible(!modalVisible);
                  if (isUpdate.update) {
                    setIsUpdate({ id: undefined, update: false });
                    setInputValues({ classNumber: "", classTitle: "" });
                  }
                }}
              >
                <Text style={{ ...styles.textStyle, color: "#2196F3" }}>
                  Cancel
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  item: {
    flex: 1,
    borderRadius: 5,
    padding: 8,
    marginRight: 10,
    marginTop: 17,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonClose: {
    backgroundColor: "white",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonConfirm: {
    backgroundColor: "#2196F3",
  },
  modalText: {
    textAlign: "center",
    padding: 4,
    color: "white",

    fontStyle: "italic",
    backgroundColor: "#13baf7",
    ...Platform.select({
      ios: {
        fontSize: 16,
      },
      android: {
        fontSize: 16,
      },
      web: {
        fontSize: 20,
      },
    }),
  },
  textInput: {
    width: "100%",
    borderColor: "#13baf7",
    borderWidth: 1,
    paddingHorizontal: 8,
    ...Platform.select({
      ios: {
        paddingVertical: 8,
        fontSize: 16,
      },
      android: {
        paddingVertical: 8,
        fontSize: 16,
      },
      web: {
        paddingVertical: 16,
        fontSize: 24,
      },
    }),
  },
  textBox: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    ...Platform.select({
      ios: {
        padding: 4,
      },
      android: {
        padding: 4,
      },
      web: {
        padding: 8,
      },
    }),
  },

  text: {
    ...Platform.select({
      ios: {
        fontSize: 14,
      },
      android: { fontSize: 14 },
      web: {
        fontSize: !isWebResponsive ? 14 : 10,
      },
    }),
  },
});
