import { useState } from "react";
import {
  FlatList,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
  Dimensions,
  Platform,
} from "react-native";
import { useDispatch } from "react-redux";
import { Feather } from "@expo/vector-icons";
import { FontAwesome } from "@expo/vector-icons";
import { Octicons } from "@expo/vector-icons";
import { useAttendance } from "../../context/attendContext.js";
import { Attendance } from "../attendance.js";
import { setStudent } from "../../store.js";

const searchMode = [
  {
    mode: "name",
    placeholder: "type your name",
    icon: "typography",
    keyboard: "default",
  },
  {
    mode: "number",
    placeholder: "type your phone number",
    icon: "number",
    keyboard: "phone-pad",
  },
  {
    mode: "classNumber",
    placeholder: "type class number",
    icon: "people",
    keyboard: "default",
  },
  {
    mode: "JDSN",
    placeholder: "type JDSN name",
    icon: "book",
    keyboard: "default",
  },
];

const deviceWidth = Dimensions.get("window").width;

let isWebResponsive = false;

if (deviceWidth < 768) {
  isWebResponsive = true;
}

export function HomeScreen({ navigation }) {
  const [value, setValue] = useState("");
  const [modeIndex, setModeIndex] = useState(0);
  const [show, setShow] = useState(false);
  const { data, findStudent, setIsLoading } = useAttendance();
  const dispatch = useDispatch();

  const onChangeValue = (str) => {
    setValue(str);
  };

  const onBtnClick = async () => {
    if (value !== "") {
      await searchStudent();
      setValue("");
    }
  };

  const searchStudent = async () => {
    setIsLoading(true);
    await findStudent(searchMode[modeIndex].mode, value);
    setIsLoading(false);
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.titleBox}>
          <Text style={{ ...styles.title }}>Attendance</Text>
        </View>
        <View style={styles.userPlusBox}>
          <Pressable
            style={styles.userPlus}
            onPress={() => navigation.navigate("Register")}
          >
            <Feather name="user-plus" size={24} color="black" />
            <Text>Register</Text>
          </Pressable>
        </View>
      </View>

      <View style={styles.body}>
        <View style={styles.searchBox}>
          <View style={{ flexDirection: "row", flex: 4 }}>
            <View
              style={{
                backgroundColor: "#13baf7",
                width: 40,
                display: "flex",
                alignItems: "center",
                paddingVertical: 8,
                borderRadius: 8,
              }}
            >
              <Pressable
                style={styles.switch}
                onPress={() =>
                  setModeIndex((modeIndex + 1) % searchMode.length)
                }
              >
                <Octicons
                  name={searchMode[(modeIndex + 1) % searchMode.length]?.icon}
                  size={24}
                  color="white"
                />
              </Pressable>
            </View>
            <TextInput
              style={styles.input}
              onChangeText={onChangeValue}
              onSubmitEditing={searchStudent}
              value={value}
              placeholder={searchMode[modeIndex].placeholder}
              keyboardType={searchMode[modeIndex].keyboard}
            />
          </View>
          <View
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <Pressable style={styles.search} onPress={onBtnClick}>
              <FontAwesome name="search" size={24} color="black" />
              <Text>Search</Text>
            </Pressable>
          </View>
        </View>

        {!show ? (
          <>
            <View style={styles.list}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginVertical: 8,
                }}
              >
                <Text style={{ ...styles.text, fontWeight: "bold" }}>
                  Class
                </Text>
                <Text style={{ ...styles.text, fontWeight: "bold" }}>Name</Text>
                <Text style={{ ...styles.text, fontWeight: "bold" }}>
                  Phone number
                </Text>
                <Text style={{ ...styles.text, fontWeight: "bold" }}>
                  Invitor 1
                </Text>
                <Text style={{ ...styles.text, fontWeight: "bold" }}>
                  Invitor 2
                </Text>
              </View>

              <FlatList
                data={Object.values(data)}
                renderItem={({ item }) => (
                  <Pressable
                    style={styles.item}
                    onPress={() => {
                      navigation.navigate("Detail");
                      dispatch(setStudent(item));
                    }}
                  >
                    <Text style={styles.text}>{item.classNumber}</Text>
                    <Text
                      style={{
                        ...styles.text,
                        textDecorationLine: item.isDropped
                          ? "line-through"
                          : "none",
                      }}
                    >
                      {item.name}
                    </Text>
                    <Text style={styles.text}>{item.phoneNumber}</Text>
                    <Text style={styles.text}>{item.invitor1}</Text>
                    <Text style={styles.text}>{item.invitor2}</Text>
                    <View style={styles.signContainer}>
                      <View
                        style={{
                          ...styles.sign,

                          backgroundColor: item.isCard ? "blue" : undefined,
                          ...Platform.select({
                            ios: { top: 10 },
                            android: { top: 10 },
                            web: { top: isWebResponsive ? 10 : 0 },
                          }),
                        }}
                      ></View>
                      <View
                        style={{
                          ...styles.sign,

                          backgroundColor: item.isInterview
                            ? "orange"
                            : undefined,
                          ...Platform.select({
                            ios: { top: 20 },
                            android: { top: 20 },
                            web: { top: isWebResponsive ? 10 : 0 },
                          }),
                        }}
                      ></View>
                    </View>
                  </Pressable>
                )}
              />
            </View>
          </>
        ) : (
          <Attendance navigation={navigation} />
        )}
        {Object.values(data).length > 0 && (
          <View style={styles.footer}>
            <Pressable style={styles.footetBtn} onPress={() => setShow(!show)}>
              <Text style={styles.btnText}>
                {!show ? "To attendance" : "To list"}
              </Text>
            </Pressable>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flex: 1,
  },
  header: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  titleBox: {
    flex: 5,
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    fontStyle: "italic",
  },
  userPlusBox: {
    flex: 1.5,
    justifyContent: "center",
    alignItems: "center",
  },
  userPlus: {
    justifyContent: "center",
    alignItems: "center",
    borderColor: "black",
    borderWidth: 1,
    borderRadius: 10,
    padding: 8,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },
  body: { flex: 6 },
  searchBox: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 14,
    paddingHorizontal: 32,
  },

  input: {
    flexGrow: 1,
    backgroundColor: "white",
    padding: 8,
    marginLeft: 16,
  },

  search: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    flex: 5,
    padding: 16,
  },
  item: {
    marginVertical: 8,
    display: "flex",
    flexDirection: "row",
  },
  text: {
    flex: 1,
    textAlign: "center",
  },
  footer: {
    justifyContent: "center",
    alignItems: "center",
  },
  footetBtn: {
    marginVertical: 16,
    backgroundColor: "#13baf7",
    borderRadius: 5,
    padding: 4,
  },
  btnText: {
    fontSize: 15,
    fontWeight: "600",
    color: "white",
    padding: 8,
  },
  signContainer: {
    position: "absolute",

    flex: 1,
    height: "100%",
    flexDirection: isWebResponsive ? "column" : "row",
    alignItems: "center",
    justifyContent: isWebResponsive ? "none" : "space-between",
    left: isWebResponsive ? 0 : 40,
    gap: 10,
  },
  sign: {
    width: 10,
    height: 10,
    borderRadius: 10,
  },
});
