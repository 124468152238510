import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  TextInput,
  KeyboardAvoidingView,
  ScrollView,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Ionicons } from "@expo/vector-icons";
import { useAttendance } from "../../context/attendContext";
import { setStudent } from "../../store";

export function EditScreen({ route, navigation }) {
  const {
    id,
    name,
    phoneNumber,
    invitor1,
    invitor2,
    classNumber,
    GSN,
    JDSN,
    url,
  } = useSelector((state) => state.student);

  const [inputValues, setInputValues] = useState({
    name,
    phoneNumber,
    invitor1: invitor1 ? invitor1 : "",
    invitor2: invitor2 ? invitor2 : "",
    classNumber,
    GSN: GSN ? GSN : "",
    JDSN: JDSN ? JDSN : "",
  });
  const { data, setData, setIsLoading, updateStudent, deleteProfile, isWeb } =
    useAttendance();
  const [images, setImages] = useState({
    currentImage: url,
    prevImage: "",
  });
  const [selectedFile, setSelectedFile] = useState(undefined);
  const dispatch = useDispatch();

  const handleChangeText = (text, key) => {
    setInputValues({
      ...inputValues,
      [key]: text,
    });
  };

  const handleImagePress = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.onchange = (e) => {
      setImages({
        currentImage: URL.createObjectURL(e.target.files[0]),
        prevImage: url,
      });
      setSelectedFile(e.target.files[0]);
    };

    input.click();
  };

  const handleConfirm = async () => {
    const { name, phoneNumber, invitor1, invitor2, classNumber, GSN, JDSN } =
      inputValues;

    const prevUrl = images.prevImage;

    setIsLoading(true);

    await updateStudent(
      id,
      {
        name,
        phoneNumber,
        invitor1,
        invitor2,
        classNumber,
        GSN,
        JDSN,
        url: !selectedFile ? url : selectedFile,
      },
      prevUrl
    )
      .then(async (result) => {
        navigation.navigate("Detail");
        dispatch(
          setStudent({
            ...data[id],
            name,
            phoneNumber,
            invitor1,
            invitor2,
            classNumber,
            GSN,
            JDSN,
            url: result.url,
          })
        );
        setData({
          ...data,
          [id]: {
            ...data[id],
            name,
            phoneNumber,
            invitor1,
            invitor2,
            classNumber,
            GSN,
            JDSN,
            url: result.url,
          },
        });

        await deleteProfile(prevUrl);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.title}>Edit your information</Text>
        </View>
        <View style={styles.studentContainer}>
          <View style={styles.infoContainer}>
            <View style={styles.h2Container}>
              <Text style={styles.h2}>Information</Text>
            </View>
            <View style={styles.basicContainer}>
              <Pressable style={styles.imgContainer} onPress={handleImagePress}>
                {!images?.currentImage ? (
                  <Ionicons name="person" size={140} color="black" />
                ) : (
                  <Image
                    source={{ uri: images.currentImage }}
                    style={{ width: "100%", height: "100%" }}
                    resizeMode="contain"
                  />
                )}
              </Pressable>
              <View style={styles.nameContainer}>
                <View style={{ ...styles.h3Container, flex: 1 }}>
                  <Text
                    style={{
                      ...styles.h3,
                      flex: 1,
                      lineHeight: 50,
                    }}
                  >
                    Name
                  </Text>
                </View>
                <View style={styles.h3Content}>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={(text) => handleChangeText(text, "name")}
                    value={inputValues.name}
                    keyboardType="default"
                  />
                </View>
                <View style={{ ...styles.h3Container, flex: 1 }}>
                  <Text style={styles.h3}>Phone Number</Text>
                </View>
                <View style={{ ...styles.h3Content, flex: 2 }}>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={(text) =>
                      handleChangeText(text, "phoneNumber")
                    }
                    value={inputValues.phoneNumber}
                    keyboardType="number-pad"
                  />
                </View>
              </View>
            </View>
          </View>

          <View style={styles.invitorContainer}>
            <View style={styles.h2Container}>
              <Text style={styles.h2}>Invitors</Text>
            </View>
            <View style={styles.invitorBox}>
              <View style={styles.invitorView}>
                <TextInput
                  style={styles.textInput}
                  onChangeText={(text) => handleChangeText(text, "invitor1")}
                  value={inputValues.invitor1}
                  keyboardType="default"
                />
              </View>
              <View style={styles.invitorView}>
                <TextInput
                  style={styles.textInput}
                  onChangeText={(text) => handleChangeText(text, "invitor2")}
                  value={inputValues.invitor2}
                  keyboardType="default"
                />
              </View>
            </View>
          </View>
          <KeyboardAvoidingView behavior="height" style={styles.classContainer}>
            <View style={{ ...styles.h2Container, flex: 1 }}>
              <Text style={styles.h2}>Class Information</Text>
            </View>
            <View style={{ flex: 5 }}>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={styles.h3Container}>
                  <Text style={styles.h3}>Class number</Text>
                </View>
                <View style={styles.h3Content}>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={(text) =>
                      handleChangeText(text, "classNumber")
                    }
                    value={inputValues.classNumber}
                    keyboardType="default"
                  />
                </View>
              </View>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={styles.h3Container}>
                  <Text style={styles.h3}>GSN</Text>
                </View>
                <View style={styles.h3Content}>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={(text) => handleChangeText(text, "GSN")}
                    value={inputValues.GSN}
                    keyboardType="default"
                  />
                </View>
              </View>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={styles.h3Container}>
                  <Text style={styles.h3}>JDSN</Text>
                </View>
                <View style={styles.h3Content}>
                  <TextInput
                    style={styles.textInput}
                    onChangeText={(text) => handleChangeText(text, "JDSN")}
                    value={inputValues.JDSN}
                    keyboardType="default"
                  />
                </View>
              </View>
            </View>
          </KeyboardAvoidingView>
        </View>
        <KeyboardAvoidingView behavior="height" style={styles.footer}>
          <Pressable style={styles.button} onPress={handleConfirm}>
            <Text style={styles.btnText}>CONFIRM</Text>
          </Pressable>
          <Pressable
            style={{ ...styles.button, backgroundColor: "grey" }}
            onPress={() => navigation.goBack()}
          >
            <Text style={styles.btnText}>BACK</Text>
          </Pressable>
        </KeyboardAvoidingView>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  header: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
  },
  title: {
    fontSize: 24,
    fontStyle: "italic",
    fontWeight: "bold",
  },

  h2Container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    padding: 8,
    backgroundColor: "#13baf7",
  },
  h2: {
    fontSize: 20,
    fontWeight: "500",
    color: "white",
  },
  h3Container: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    backgroundColor: "#70d5f9",
    borderColor: "#13baf7",
    borderStyle: "solid",
    borderWidth: 1,
  },
  h3: {
    fontSize: 18,
    fontWeight: "500",
    textAlign: "center",
  },
  h3Content: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    borderColor: "#13baf7",
    borderStyle: "solid",
    borderWidth: 1,
  },
  studentContainer: {
    flex: 8,
    width: "80%",
    borderColor: "#13baf7",
    borderStyle: "solid",
    borderWidth: 1,
  },
  infoContainer: {
    flex: 1,
  },
  basicContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 8,
    width: "100%",
  },
  imgContainer: {
    width: "50%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  nameContainer: {
    width: "50%",
  },
  invitorContainer: {
    flex: 0.3,
  },
  invitorBox: { flexDirection: "row", flex: 2 },
  invitorView: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#13baf7",
    borderStyle: "solid",
    borderWidth: 1,
  },
  classContainer: {
    flex: 1,
  },

  footer: {
    flex: 1,
    flexDirection: "row",
    width: "80%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  button: {
    marginVertical: 16,
    backgroundColor: "#6950f4",
    borderRadius: 5,
    padding: 4,
  },
  btnText: {
    fontSize: 15,
    fontWeight: "600",
    color: "white",
    padding: 8,
  },
  textInput: {
    width: "80%",
    backgroundColor: "white",
    textAlign: "center",
  },
});
