import { configureStore, createSlice } from "@reduxjs/toolkit";

const studentSlice = createSlice({
  name: "student",
  initialState: {},
  reducers: {
    setStudent: (state, action) => {
      return action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    student: studentSlice.reducer,
  },
});

export const { setStudent } = studentSlice.actions;

export default store;
