import { StatusBar } from "expo-status-bar";
import { Provider } from "react-redux";
import { NavigationContainer } from "@react-navigation/native";
import { RegisterScreen } from "./component/RegisterScreen/registerScreen";
import { HomeScreen } from "./component/HomeScreen/homeScreen";
import { CameraScreen } from "./component/cameraScreen.js";
import { DetailScreen } from "./component/detailScreen.js";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { CalendarScreen } from "./component/calendarScreen.js";
import { Ionicons } from "@expo/vector-icons";
import { AttendanceProvider } from "./context/attendContext.js";
import { EditScreen } from "./component/EditScreen/editScreen";
import { Platform } from "react-native";
import store from "./store.js";

const HomeStack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

function HomeStackScreen() {
  return (
    <HomeStack.Navigator initialRouteName="HomeCheck">
      <HomeStack.Screen
        name="HomeCheck"
        component={HomeScreen}
        options={{ headerShown: false }}
      />
      <HomeStack.Screen
        name="Register"
        component={RegisterScreen}
        options={{ headerShown: false }}
      />
      <HomeStack.Screen
        name="Camera"
        component={CameraScreen}
        options={{ headerShown: false }}
      />
      <HomeStack.Screen
        name="Detail"
        component={DetailScreen}
        options={{ headerShown: false }}
      />
      <HomeStack.Screen
        name="Edit"
        component={EditScreen}
        options={{ headerShown: false }}
      />
    </HomeStack.Navigator>
  );
}

export default function App() {
  return (
    <Provider store={store}>
      <AttendanceProvider>
        <StatusBar
          style={Platform.OS === "ios" ? "dark" : "light"}
          backgroundColor="#13baf7"
        />
        <NavigationContainer>
          <Tab.Navigator
            initialRouteName="HomeStack"
            screenOptions={({ route }) => ({
              tabBarIcon: ({ focused, color, size }) => {
                let iconName;

                if (route.name === "Home") {
                  iconName = focused ? "home" : "home-outline";
                } else if (route.name === "Calendar") {
                  iconName = focused ? "calendar" : "calendar-outline";
                }

                return <Ionicons name={iconName} size={size} color={color} />;
              },
              tabBarHideOnKeyboard: true,
              tabBarActiveTintColor: "#13baf7",
              tabBarInactiveTintColor: "gray",
              headerShown: false,
              tabBarStyle: [
                {
                  display: "flex",
                },
                null,
              ],
            })}
          >
            <Tab.Screen name="Home" component={HomeStackScreen} />
            <Tab.Screen name="Calendar" component={CalendarScreen} />
          </Tab.Navigator>
        </NavigationContainer>
      </AttendanceProvider>
    </Provider>
  );
}
