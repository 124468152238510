import {
  FlatList,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Dimensions,
  Pressable,
  Platform,
} from "react-native";
import { useDispatch } from "react-redux";
import { useAttendance } from "../context/attendContext";
import { Foundation } from "@expo/vector-icons";
import { setStudent } from "../store";

const deviceWidth = Dimensions.get("window").width;
let isTablet = false;
if (deviceWidth > 768) {
  isTablet = true;
}

export function Attendance({ navigation }) {
  const { data } = useAttendance();
  const date = Object.values(data)[0]?.monthClasses;
  const dispatch = useDispatch();

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{ flexDirection: "row" }}>
        <View style={styles.nameContainer}>
          <View style={styles.nameBox}>
            <Text style={{ ...styles.name, fontWeight: "bold" }}>
              Name\Date
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            {Object.values(data).map((item, index) => (
              <Pressable
                key={`${item.id}-${index}`}
                style={styles.nameBox}
                onPress={() => {
                  navigation.navigate("Detail");
                  dispatch(setStudent(item));
                }}
              >
                <Text
                  style={{
                    ...styles.name,
                    backgroundColor: !item.isDropped ? "#13baf7" : "red",
                    borderRadius: 5,
                    paddingHorizontal: isTablet ? 8 : 4,
                    paddingVertical: isTablet ? 4 : 2,
                    color: "white",
                    overflow: "hidden",
                  }}
                  numberOfLines={1}
                >
                  {`${item.name.split(" ")[0]} ${item.name.split(" ")[1]}`}
                </Text>
              </Pressable>
            ))}
          </View>
        </View>
        <View style={styles.statusContainer}>
          <ScrollView horizontal>
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "row" }}>
                {date.map((item) => (
                  <View key={item.classSessionId} style={styles.statusBox}>
                    <Text
                      style={{
                        ...styles.text,
                        paddingHorizontal: 8,
                        paddingVertical: 4,
                        backgroundColor: "#2196F3",
                        color: "white",
                        borderRadius: 5,
                        overflow: "hidden",
                      }}
                    >
                      {item.classDate.substring(5, 10)}
                    </Text>
                  </View>
                ))}
              </View>
              <View style={{ flex: 1 }}>
                {Object.values(data).map((item) => (
                  <View key={item.id} style={{ flexDirection: "row" }}>
                    {item.allState.map((i) => (
                      <View
                        key={i.attendId}
                        style={{ ...styles.statusBox, flex: 1 }}
                      >
                        <Text
                          style={{
                            ...styles.text,
                            width: "50%",
                            borderRadius: 5,
                          }}
                        >
                          {i.status ? (
                            <Foundation name="check" size={24} color="green" />
                          ) : (
                            <Foundation name="x" size={24} color="red" />
                          )}
                        </Text>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>
          </ScrollView>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 5,
    padding: 16,
  },
  nameBox: {
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    height: 40,
  },

  name: {
    width: "100%",
    fontSize: 14,
  },
  text: { fontSize: 14, textAlign: "center" },
  nameContainer: { flex: isTablet ? 1.5 : 2.5 },
  statusContainer: { flex: 5 },
  statusBox: {
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    height: 40,
  },
});
