export default class AttendanceRepository {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async findStudent(mode, value) {
    let url;
    switch (mode) {
      case "name":
        url = `${this.baseUrl}/student?name=${value}`;
        break;
      case "number":
        url = `${this.baseUrl}/student?phoneNumber=${value}`;
        break;
      case "classNumber":
        url = `${this.baseUrl}/student?classNumber=${value}`;
        break;
      case "JDSN":
        url = `${this.baseUrl}/student?JDSN=${value}`;
      default:
        break;
    }

    const res = await fetch(url, {
      method: "GET",
    });

    return await res.json();
  }

  async registerStudent(data) {
    const { name, invitor1, invitor2, classNumber, phoneNumber, uri } = data;

    let formData = new FormData();
    if (typeof uri === "string") {
      formData.append("file", {
        uri,
        name: `${name}_${Date.now()}`,
        type: "image/jpeg",
      });
    } else {
      const file = new File([uri], `${name}_${Date.now()}`, { type: uri.type });
      formData.append("file", file);
    }

    const res = await fetch(`${this.baseUrl}/upload/image`, {
      method: "POST",
      body: formData,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    });

    const result = await res.json();

    const response = await fetch(`${this.baseUrl}/student/register`, {
      method: "POST",
      body: JSON.stringify({
        name,
        invitor1,
        invitor2,
        classNumber,
        phoneNumber,
        url: result.file,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await response.json();
  }

  async updateStudent(id, data, prevUrl) {
    const {
      name,
      phoneNumber,
      invitor1,
      invitor2,
      JDSN,
      classNumber,
      GSN,
      url,
    } = data;

    let result;

    if (prevUrl) {
      let formData = new FormData();
      if (typeof url === "string") {
        console.log(url);
        formData.append("file", {
          uri: url,
          name: `${name}_${Date.now()}`,
          type: "image/jpeg",
        });
      } else {
        const file = new File([url], `${name}_${Date.now()}`, {
          type: url.type,
        });
        formData.append("file", file);
      }

      const res = await fetch(`${this.baseUrl}/upload/image`, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      result = await res.json();
    }

    const response = await fetch(`${this.baseUrl}/student/update?id=${id}`, {
      method: "PUT",
      body: JSON.stringify({
        name,
        phoneNumber,
        invitor1,
        invitor2,
        JDSN,
        classNumber,
        GSN,
        url: prevUrl ? result?.file : url,
      }),

      headers: {
        "Content-Type": "application/json",
      },
    });

    return await response.json();
  }

  async checkStudent(id, data) {
    const { isCard, isInterview, isDropped } = data;
    const res = await fetch(`${this.baseUrl}/student/check?id=${id}`, {
      method: "PUT",
      body: JSON.stringify({ isCard, isInterview, isDropped }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return res.json();
  }

  async deleteProfile(url) {
    return await fetch(`${this.baseUrl}/upload/delete?url=${url}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async getClasses(date) {
    const response = await fetch(`${this.baseUrl}/class?date=${date}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await response.json();
  }

  async makeClass(classTitle, classNumber, classDate) {
    const res = await fetch(`${this.baseUrl}/class/make`, {
      method: "POST",
      body: JSON.stringify({
        classTitle,
        classNumber,
        classDate,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await res.json();
  }

  async renameClass(classSessionId, classTitle, classNumber) {
    const res = await fetch(
      `${this.baseUrl}/class/update?classSessionId=${classSessionId}`,
      {
        method: "PUT",
        body: JSON.stringify({
          classTitle,
          classNumber,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return await res.json();
  }

  async deleteClass(classSessionId) {
    const res = await fetch(
      `${this.baseUrl}/class/delete?classSessionId=${classSessionId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return await res.json();
  }

  async attendClass(studentId, status, classNumber) {
    const res = await fetch(`${this.baseUrl}/attend/check`, {
      method: "POST",
      body: JSON.stringify({
        studentId,
        status,
        classNumber,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await res.json();
  }

  async catchUpClass(studentId, status, classNumber, classDate) {
    const res = await fetch(`${this.baseUrl}/attend/catchup`, {
      method: "POST",
      body: JSON.stringify({
        studentId,
        status,
        classNumber,
        classDate,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    return await res.json();
  }
}
