import { createContext, useContext, useEffect, useMemo, useState } from "react";
import AttendanceRepository from "../src/attendanceRepository.js";
import Loading from "../component/loading.js";
import { StyleSheet, View, Platform } from "react-native";

const BASE_URL = "https://port-0-myserver-12fhqa2blna2yzrz.sel5.cloudtype.app";
// const BASE_URL = "http://35.243.200.88:8080";
const AttendContext = createContext({});
const attendanceRepository = new AttendanceRepository(BASE_URL);

export function AttendanceProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [student, setStudent] = useState({});
  const isWeb = Platform.OS === "web" ? true : false;

  const findStudent = async (mode, value) => {
    return await attendanceRepository
      .findStudent(mode, value)
      .then((result) => {
        const dataObj = {};
        result.forEach((item) => (dataObj[item.id] = item));
        setData(dataObj);
      })
      .catch(console.log);
  };

  const registerStudent = async (data) => {
    return await attendanceRepository.registerStudent(data);
  };

  const updateStudent = async (id, data, prevUrl) => {
    return await attendanceRepository.updateStudent(id, data, prevUrl);
  };

  const checkStudent = async (id, data) => {
    return await attendanceRepository.checkStudent(id, data);
  };

  const deleteProfile = async (url) => {
    return await attendanceRepository.deleteProfile(url);
  };

  const getClasses = async (date) => {
    return await attendanceRepository.getClasses(date);
  };

  const makeClass = async (classTitle, classNumber, classDate) => {
    return await attendanceRepository.makeClass(
      classTitle,
      classNumber,
      classDate
    );
  };

  const renameClass = async (classSessionId, classTitle, classNumber) => {
    return await attendanceRepository.renameClass(
      classSessionId,
      classTitle,
      classNumber
    );
  };

  const deleteClass = async (classSessionId) => {
    return await attendanceRepository.deleteClass(classSessionId);
  };

  const attendClass = async (studentId, status, classNumber) => {
    return await attendanceRepository.attendClass(
      studentId,
      status,
      classNumber
    );
  };

  const catchUpClass = async (studentId, status, classNumber, classDate) => {
    return await attendanceRepository.catchUpClass(
      studentId,
      status,
      classNumber,
      classDate
    );
  };

  const context = useMemo(
    () => ({
      isWeb,
      data,
      student,
      setStudent,
      setData,
      setIsLoading,
      findStudent,
      registerStudent,
      makeClass,
      renameClass,
      getClasses,
      deleteClass,
      attendClass,
      catchUpClass,
      updateStudent,
      checkStudent,
      deleteProfile,
    }),
    [
      isWeb,
      data,
      student,
      setStudent,
      setData,
      setIsLoading,
      findStudent,
      registerStudent,
      getClasses,
      makeClass,
      renameClass,
      deleteClass,
      attendClass,
      catchUpClass,
      updateStudent,
      checkStudent,
      deleteProfile,
    ]
  );
  return (
    <AttendContext.Provider value={context}>
      <View style={styles.container}>
        {isLoading && <Loading isLoading={isLoading} />}
        {children}
      </View>
    </AttendContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      ios: { flex: 1, paddingTop: 20, backgroundColor: "#f0f0f0" },
      android: { flex: 1, paddingTop: 20 },
      web: { flex: 1 },
    }),
  },
});

export const useAttendance = () => useContext(AttendContext);
